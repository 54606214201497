import React from "react";
import g from "glamorous";
import Layout from "../components/layout";
import { css } from "glamor";
import Menu from "../components/Menu";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
import { Container } from "glamorous-grid";
import Masonry from "react-masonry-component";
import { FaPlus } from "react-icons/fa";

import elle from "../images/elle-septembre-2014-couverture.jpg";
import madameFigaro1 from "../images/madame-figaro-couverture-1.jpg";
import madameFigaro2 from "../images/madame-figaro-couverture-2.jpg";
import rajeunir from "../images/rajeunir-magazine-couverture.jpg";
import plasticBeaute from "../images/plastique-beaute-couverture.jpg";
import isa from "../images/isa-couverture.jpg";
import iciParis from "../images/ici-paris-couverture.jpg";
import santeMag from "../images/sante-mag-couverture.jpg";
import { FaHome } from "react-icons/fa";
import { Link } from "gatsby";

const Wrapper = g.main({
  maxWidth: `1000px`,
  margin: `0 auto`,
  paddingBottom: `4em`,
});

const Title = g.h1({
  textAlign: `center`,
  fontSize: `34px`,
  color: `#3c4d68`,
  margin: `1em auto 1.5em`,
  fontVariant: `none`,
  fontWeight: `600`,
  letterSpacing: `.01em`,
  lineHeight: `48px`,
  "@media(max-width: 767px)": {
    fontSize: `23px`,
    LineHeight: `31px`,
  },
});

const Li = g.li({
  width: `33%`,
  margin: `1em 0 1em`,

  "@media(max-width: 992px)": {
    width: `50%`,
  },
  "@media(max-width: 771px)": {
    width: `100%`,
  },
});

const Tile = g.section({
  backgroundColor: `white`,
  padding: `0.4em 1em 1em`,
  width: `85%`,
  margin: `0 auto`,
  borderRadius: `4px`,
  border: `1px solid rgba(73,105,126,0.08)`,
  boxShadow: `0 1px 7px rgba(0,0,0,.05)`,
});

const Testimonial = g.p({});

const Img = g.img({
  width: `100%`,
  marginTop: `8px`,
});

const H2 = g.h2({
  fontSize: `23px`,
  lineHeight: `30px`,
  margin: `.3em 0 .4em`,
  textAlign: `center`,
});

const LinkWrapper = g.div({
  textAlign: `right`,
});

const LinkPlus = g.a({
  color: `#3c4d68`,
  textDecoration: `none`,
  border: `none`,
  width: `100%`,
});

const cssPlus = css({
  fontSize: `1em`,
  padding: `0 .4em .12em`,
});

const masonryOptions = {
  transitionDuration: 0,
};

const Breadcrumb = g.ul({
  fontSize: `13px`,
  lineHeight: `20px`,
  marginLeft: `0 !important`,
  marginTop: `0`,
  marginBottom: `10px !important`,
  fontWeight: `500`,
  letterSpacing: `.015em`,
});

const BreadcrumbItem = g.li({
  display: `inline`,
  "&:first-of-type": {
    "&::before": {
      content: `none`,
    },
  },
  "&::before": {
    content: `/`,
    padding: `0 .5em`,
  },
});

const cssBreadcrumbLink = css({
  color: `#3c4d68`,
  borderBottom: `none`,
});

const imagesLoadedOptions = { background: ".my-bg-image-el" };

export default () => (
  <Layout>
    <g.Div id="page-wrap" margin={`0 auto`} backgroundColor={`#fcfcfc`}>
      <Helmet>
        <title>
          Revue de Presse sur la chirurgie esthétique | Dr Cédric Kron
        </title>
        <meta
          name="description"
          content="Revue de presse du Dr Cédric Kron, chirurgien esthétique à Paris, spécialiste de la chirurgie esthétique, plastique et reconstructrice du corps et du visage."
        />
        <html lang="fr" />
        <script type="application/ld+json">{` {
        "@context": "http://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "name": "Revue de presse",
        "item": "https://www.dr-kron.com/revue-de-presse/"
        }]
            }
  `}</script>
      </Helmet>
      <Menu pathEn="/en/" />

      <Wrapper>
        <Container css={{ padding: `0 15px !important` }}>
          <Title>Revue de presse</Title>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/" {...cssBreadcrumbLink} title="Page d'accueil">
                <FaHome
                  css={{
                    fontSize: `1.2em`,
                    paddingBottom: `.2em`,
                    transform: `translateY(5px)`,
                  }}
                />
              </Link>
            </BreadcrumbItem>

            <BreadcrumbItem>Revue de presse</BreadcrumbItem>
          </Breadcrumb>

          <Masonry
            className={"my-gallery-class"} // default ''
            elementType={"ul"} // default 'div'
            options={masonryOptions} // default {}
            disableImagesLoaded={false} // default false
            updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
            imagesLoadedOptions={imagesLoadedOptions} // default {}
            css={{ listStyleType: `none`, margin: `0` }}
          >
            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <Img src={elle} />
                  <H2>Lifting : Que pensez des nouveaux fils tenseurs ? </H2>
                  «&nbsp;Ce n'est pas justifié de placer des fils tenseurs non
                  résorbables sur un visage qui continue de vieillir, ils ne
                  seront plus au bon endroit deux à cinq ans plus tard&nbsp;»,
                  affirme le Dr Cédric Kron, chirurgien esthétique, enterrant
                  les fils d'or et autres «&nbsp;tortures&nbsp;» des années 90
                  qui migraient, ressortaient, se plaçaient en accordéon.
                </Testimonial>
                <LinkWrapper>
                  <LinkPlus
                    href="http://www.elle.fr/Beaute/Soins/Tendances/Lifting-que-penser-des-nouveaux-fils-tenseurs-2830286"
                    target="_blank"
                  >
                    <FaPlus {...cssPlus} />
                    Consulter l'article sur Elle.fr
                  </LinkPlus>
                </LinkWrapper>
              </Tile>
            </Li>

            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <Img
                    src={madameFigaro2}
                    alt="Article du magazine Madame Figaro sur la beauté et les traitements esthétiques"
                  />
                  <H2>Ce qui vous rend belles</H2>
                  Quand une patiente arrive ne disant "Je veux un lifting ou un
                  liposuccion", il faut l’arrêter tout de suite et commencer par
                  essayer de cerner sa demande. S’assurer qu’elle se fait bien
                  opérer pour elle, comprendre ses motivations, ses attentes.
                </Testimonial>
                <LinkWrapper>
                  <LinkPlus href="/pdf/madame-figaro-2.pdf" target="_blank">
                    <FaPlus {...cssPlus} />
                    Consulter l'article (pdf)
                  </LinkPlus>
                </LinkWrapper>
              </Tile>
            </Li>

            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <Img
                    src={madameFigaro1}
                    alt="Article de Madame Figaro sur les traitements esthétiques du décolletés"
                  />
                  <H2>Sublimer votre décolleté</H2>
                  On pose de plus en plus de prothèses anatomiques en forme de
                  goutte ou de poire, moins spectaculaire que les rondes mais
                  souvent plus naturelles. Le plus important&nbsp;: que la
                  poitrine soit en harmonie avec le reste de la silhouette. Elle
                  doit mettre en valeur le corps, de face comme de profil.
                </Testimonial>
                <LinkWrapper>
                  <LinkPlus href="/pdf/madame-figaro.pdf" target="_blank">
                    <FaPlus {...cssPlus} />
                    Consulter l'article (pdf)
                  </LinkPlus>
                </LinkWrapper>
              </Tile>
            </Li>

            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <Img
                    src={rajeunir}
                    alt="Article du magazine Rajeunir sur les traitements esthétiques du nez à Paris"
                  />
                  <H2>Le nez : des traitements de rajeunissement adaptés</H2>
                  Souvent, l’état du nez ne nécessite pas de grosse intervention
                  et on peut réparer les modifications induites par l'âge.
                </Testimonial>
                <LinkWrapper>
                  <LinkPlus href="/pdf/rajeunir-magazine.pdf" target="_blank">
                    <FaPlus {...cssPlus} />
                    Consulter l'article (pdf)
                  </LinkPlus>
                </LinkWrapper>
              </Tile>
            </Li>

            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <Img
                    src={plasticBeaute}
                    alt="Article du magazine Plastique et beauté sur la rhinoplastie"
                  />
                  <H2>Rhinoplastie</H2>
                  L’image d'un nez désiré n'existe que dans l’esprit du patient.
                  C'est au chirurgien de la comprendre lors des consultations
                  est de lui expliquer dans quelle mesure on peut l'obtenir en
                  tout ou partie. Les images sont accessoires.
                </Testimonial>
                <LinkWrapper>
                  <LinkPlus href="/pdf/plastique-beaute.pdf" target="_blank">
                    <FaPlus {...cssPlus} />
                    Consulter l'article (pdf)
                  </LinkPlus>
                </LinkWrapper>
              </Tile>
            </Li>

            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <Img
                    src={isa}
                    alt="Article du magazine Isa la chirurgie esthétique du visage et du double menton"
                  />
                  <H2>Un oval parfait</H2>
                  Pour le double menton, on pratique une lipoaspiration (sous
                  anesthésie local, avec une incision derrière les oreilles et
                  sous le menton), elle permet d’éliminer le gras et provoque
                  mécaniquement une rétraction de la peau. Il faut attendre
                  quelques semaines pour voir un résultat définitif. Et surtout
                  s’en remettre à des mains expertes.
                </Testimonial>
                <LinkWrapper>
                  <LinkPlus href="/pdf/isa.pdf" target="_blank">
                    <FaPlus {...cssPlus} />
                    Consulter l'article (pdf)
                  </LinkPlus>
                </LinkWrapper>
              </Tile>
            </Li>

            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <Img
                    src={iciParis}
                    alt="Article du magazine Ici Paris sur l'augmentation mammaire et la chirurgie des seins"
                  />
                  <H2>Augmentation mammaire</H2>
                  Mettez fin à vos complexes en choisissant la forme et le
                  volume de votre poitrine. Avec ou sans chirurgie, il est
                  aujourd'hui possible d'offrir plus de volume à ses seins afin
                  de se sentir mieux dans son corps. Nous avons passé ces
                  techniques en revue.
                </Testimonial>
                <LinkWrapper>
                  <LinkPlus href="/pdf/sante-mag.pdf" target="_blank">
                    <FaPlus {...cssPlus} />
                    Consulter l'article (pdf)
                  </LinkPlus>
                </LinkWrapper>
              </Tile>
            </Li>

            <Li className="image-element-class">
              <Tile>
                <Testimonial>
                  <Img
                    src={santeMag}
                    alt="Article du magazine Santé Magazine sur le Dr Cédric Kron, chirurgien esthétique à Paris"
                  />
                  <H2>Le médecin aux doigts d’or</H2>
                  Tout dans son métier passionne ce chirurgien de 42 ans,
                  spécialiste en chirurgie plastique, reconstructrice et
                  esthétique. Il nous a accueillis dans ses consultations et au
                  bloc.
                </Testimonial>
                <LinkWrapper>
                  <LinkPlus href="/pdf/sante-mag.pdf" target="_blank">
                    <FaPlus {...cssPlus} />
                    Consulter l'article (pdf)
                  </LinkPlus>
                </LinkWrapper>
              </Tile>
            </Li>
          </Masonry>
        </Container>
      </Wrapper>

      <Footer />
    </g.Div>
  </Layout>
);
