import React from "react";
import { css } from "glamor";
import { Helmet } from "react-helmet";

css.global("*", { padding: "0" });
css.global("*", { margin: "0" });

css.global("html, body", { color: "#1f1f1f" });
css.global("html, body", {
  font:
    "400 16px/26px -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
});

css.global("strong, b", {
  font:
    "600 16px/26px -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
});

css.global("h1", {
  fontFamily:
    "'SF Pro Display', 'SF Pro Text', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
});

css.global("h2", {
  font:
    "500 26px/35px fontFamily: 'SF Pro Display', 'SF Pro Text', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
});

css.global("h2", {
  color: "#3c4d68",
});

css.global("h2", {
  margin: ".9em 0 .4em",
  lineHeight: "1.3em",
});

css.global("h2", {
  letterSpacing: ".02em",
});

css.global("h3", {
  font:
    "500 19px/27px -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
});

css.global("h3", {
  color: "#3c4d68",
});

css.global("h3", {
  margin: ".5em 0 .4em",
});

css.global("h3", {
  letterSpacing: ".01em",
});

css.global("h4", {
  marginBottom: ".5em",
});

css.global("html, body", {
  WebkitFontSmoothing: "antialiased",
});

css.global("html, body", {
  MozOsxFontSmoothing: "grayscale",
});

css.global("a, p a, ul li a", { textDecoration: "none" });
css.global("p a:focus, ul li a:focus", { outline: "none" });
css.global("p a:focus, ul li a:focus", { opacity: ".9" });
css.global("p", { margin: "0 0 .9em" });
css.global("p a, ul li a", { color: "#2c2e31" });
css.global("p a, ul li a", { padding: ".08em" });
css.global("p a, ul li a", { borderBottom: "1px solid #2c2e31" });
css.global("p a, ul li a", { transition: "all .3s" });
css.global("p a:hover, ul li a:hover", { textDecoration: "none" });
css.global("p a:hover, ul li a:hover", {
  backgroundColor: "hsla(193,5%,62%,.2)",
});
css.global("p a:hover, ul li a:hover", { borderColor: "hsla(193,5%,62%,.2)" });
css.global("p a:hover, ul li a:hover", { transition: "all .3s" });

css.global("ul", { margin: "0 0 .8em 1.6em !important" });
css.global("ol", { margin: "0 0 .8em 2em !important" });

css.global("::placeholder", { color: "white !important" });

css.global("input::focus", { outline: "none !important" });

css.global(".intro", {
  font:
    "400 21px/31px -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
});

css.insert(
  ".videoWrapper{position:relative;padding-bottom:56.25%;padding-top:25px;height:0;}.videoWrapper iframe{position:absolute;top:0;left:0;width:100%;height:100%;}@media(max-width:768px) {.intro {font-size:19px;line-height:28px;}.responsive-image{width: 100% !important; margin-top: 0 !important;}h2{font-size: 25px ; line-height:32px; letter-spacing: .01em;}ul{margin: 0 0 .1em 1em !important;}}"
);

css.global("body-contouring", { margin: "0" });

css.global(".align-right", { textAlign: "right" });

export default ({ children }) => (
  <div>
    <Helmet>
      <script type="application/ld+json">{`
{
  "@context": "http://schema.org",
  "@type": "Person",
  "name": "Dr Cédric Kron",
  "url": "https://www.dr-kron.com/",
  "sameAs": [
    "https://www.facebook.com/DrCedricKron",
    "https://www.instagram.com/docteurkron/",
    "https://twitter.com/Dr_Kron",
    "https://www.youtube.com/channel/UCCbRUwoTEbXraAeMVIN9DuA",
    "https://www.linkedin.com/in/kron-c%C3%A9dric-28492917",
    "https://plus.google.com/+DocteurKron"
  ]
}
`}</script>
      <script type="application/ld+json">{`
{
  "@context": "http://schema.org",
  "@type": "Organization",
  "url": "https://www.dr-kron.com",
  "contactPoint": [
    { "@type": "ContactPoint",
      "telephone": "+33-1-45-62-85-00",
      "contactType": "reservations"
    }
  ]
}
`}</script>
      <link rel="preconnect" href="https://www.dr-kron.com" />
    </Helmet>

    {children}
  </div>
);

css.global(".sommaire", {
  border: "1px solid rgba(73,105,126,0.08)",
  boxShadow: "0 1px 7px rgba(0,0,0,.05)",
  borderRadius: `5px`,
  backgroundColor: `white`,
  padding: `.8em 1em .5em 1em`,
  display: `flex`,
});

css.global(".sommaire-titre", {
  display: `inline-block`,
  width: `20%`,
  color: `#3c4d68`,
  letterSpacing: `.03em`,
  textTransform: `uppercase`,
  fontSize: `.9em`,
  fontWeight: `600`,
  margin: `0 0 .6em`,
  padding: `0`,
});

css.global(".sommaire ul", {
  display: `inline-block`,
  width: `80%`,
  listStyleType: `none`,
  margin: `0 !important`,
  padding: `0`,
});

css.global(".sommaire ul li", {
  margin: `0 0 .5em`,
  fontSize: `.9em`,
  lineHeight: `1.5em`,
});

css.global(".sommaire ul li::before", {
  marginRight: `5px`,
});

css.global(".sommaire a", {
  borderBottom: `none`,
});

css.insert(
  "@media(max-width:768px) { .sommaire { display: block; } .sommaire-titre { display: block; width: 100%;} .sommaire ul {display: block; width: 100%;}}"
);

css.global("table", {
  border: `1px solid rgba(73,105,126,0.08)`,
  boxShadow: `0 1px 7px rgba(0,0,0,.05)`,
  borderRadius: `4px`,
  backgroundColor: `white`,
  padding: `.5em .22em .7em`,
  fontSize: `.88em`,
  lineHeight: `1.63em`,
  tableLayout: `fixed`,
  wordWrap: `break-word`,
});

css.global("table p", {
  fontSize: `1em`,
  lineHeight: `1.63em`,
});

css.global("table strong", {
  fontSize: `1em`,
  lineHeight: `1.63em`,
});

css.global("table th", {
  color: `#49697e`,
  fontSize: `1.5em`,
  fontWeight: `600`,
  padding: `.5em`,
});

css.global("table td", {
  padding: `.45em .35em`,
  verticalAlign: `top`,
});

css.global("table td.subtitle", {
  fontSize: `1.25em`,
  fontWeight: `600`,
  color: `#49697e`,
});

css.global("table td.link", {
  textAlign: `center`,
  fontWeight: `600`,
});

css.global("table td.link a", {
  color: `#49697e !important`,
});

css.global(".legende", {
  textAlign: `center`,
  fontSize: `0.87em`,
  paddingTop: `-8px`,
  lineHeight: `1.5em`,
  fontStyle: `italic`,
});
